import React from 'react';
import { Typography, List, ListItem, ListItemText } from '@material-ui/core';

const Review = ({ checkoutToken }) => {

  console.log("test?????",checkoutToken);

  return(
  <>
    <Typography variant="h6" gutterBottom>Order summary</Typography>
    <List disablePadding>
      {/* {checkoutToken.live.line_items.map((product) => ( */}
        <ListItem style={{ padding: '10px 0' }}>
          <ListItemText primary={checkoutToken.name} secondary={`Quantity: ${1}`} />
          <Typography variant="body2">{checkoutToken.ticket_types.pricing[0].price}</Typography>
        </ListItem>
      {/* ))} */}
      <ListItem style={{ padding: '10px 0' }}>
        <ListItemText primary="Total" /> 
        <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
          {checkoutToken.ticket_types.pricing[0].price}
        </Typography>
      </ListItem>
    </List>
  </>
)
  }

export default Review;
