import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import logo from "../../assets/circles.png";
// import "./style.css";
import "mdbreact/dist/css/mdb.css"; 
import { Apple, Android } from "@material-ui/icons";

const Footer = () => {
  return (
    <MDBFooter className="black font-small pt-4 mt-4">
      <MDBContainer className="text-center text-md-left">
        <MDBRow className="text-center text-md-left mt-3 pb-3">
          <MDBCol md="3" lg="3" xl="4" className="mx-auto mt-3">
            <h6 className="text-uppercase mb-4 font-weight-bold">
              <img src={logo} alt="Book Store App" height="50px" />
              {/* <strong>Foursho</strong> */}
            </h6>
            <p>
           Download Foursho
            </p>
           
            <li className="list-inline-item">
                  <a
                    className="btn-floating btn-sm rgba-white-slight mx-1"
                    href="https://play.google.com/store/search?q=foursho&c=apps"
                  >
                    <i className="fab fa-google  fa-2x" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="btn-floating btn-sm rgba-white-slight mx-1"
                    href=""
                  >
                    <i className="fab fa-apple fa-2x" />
                  </a>
                </li><br></br><br></br>
                <a href="">Privacy policy</a> | <a href="">Refund policy</a> | <a href="">Terms and condition</a>
          </MDBCol>
          <hr className="w-100 clearfix d-md-none" />
          <MDBCol md="2" lg="2" xl="2" className="mx-auto mt-3">
            <h6 className="text-uppercase mb-4 font-weight-bold">
              {/* <strong>T</strong> */}
            </h6>
            <p>
              <a href="#"></a>
            </p>
            <p>
              {/* <a href="">Portfolio</a> */}
            </p>
          </MDBCol>
        
          <hr className="w-100 clearfix d-md-none" />
          <MDBCol md="4" lg="3" xl="3" className="mx-auto mt-3">
            <h6 className="text-uppercase mb-4 font-weight-bold">
              <br></br>
              <strong>Contact</strong>
            </h6>
            <p>
              <i className="fa fa-envelope mr-3" /> connect@foursho.com
            </p>
            <p>
              <i className="fa fa-phone mr-3" /> +918891193422
            </p>
          </MDBCol>
        </MDBRow>
        <hr />
        <MDBRow className="d-flex align-items-center">
          <MDBCol md="8" lg="8">
            <p className="text-center text-md-left grey-text">
              &copy; {new Date().getFullYear()} Made by <span> </span>
              <a href="https://wecodelife.com/">WecodeLife</a>
            </p>
          </MDBCol>
          <MDBCol md="4" lg="4" className="ml-lg-0">
            <div className="text-center text-md-right">
              <ul className="list-unstyled list-inline">
                <li className="list-inline-item">
                  <a
                    className="btn-floating btn-sm rgba-white-slight mx-1"
                    href=""
                  >
                    <i className="fab fa-instagram" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="btn-floating btn-sm rgba-white-slight mx-1"
                    href=""
                  >
                    <i className="fab fa-twitter" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    className="btn-floating btn-sm rgba-white-slight mx-1"
                    href=""
                  >
                    <i className="fab fa-whatsapp" />
                  </a>
                </li>
              </ul>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    </MDBFooter>
  );
};

export default Footer;
