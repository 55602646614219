import React from 'react';

const PrivacyPolicy = () => {
  return (
    

    <li>dddd</li>
    
  );
};

export default PrivacyPolicy;
