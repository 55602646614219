import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(() => ({
  root: {
    maxWidth: "100%",
    background: "#1A1A1A",
    borderRadius: 15, // Set the border radius for rounded corners
    overflow: 'hidden', // Ensure content inside the card doesn't overflow the rounded corners
  },
  media: {
    height: 0,
    paddingTop: "65%",
    "&:hover": {
      backgroundColor: "#2a344a",
      boxShadow: "none",
      
    },
  },
  cardActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  cardContent: {
    display: "flex",
    justifyContent: "center",
    color:"white",
  },
  
  button: {
    background: "#ff0000",
    color: "white",
    width: "100%",
    marginBottom:"25px",
    borderRadius: 15,
    height: "40px",

    "&:hover": {
      backgroundColor: "#FFFFFF",
      boxShadow: "none",
      color: "black",
    },
  },
}));
