import React, { useState, useEffect, useRef } from 'react';
import { CssBaseline, Paper, Stepper, Step, StepLabel, Typography, Grid, CircularProgress, Divider, Button } from '@material-ui/core';
import { useForm, FormProvider } from 'react-hook-form';
import { Link } from 'react-router-dom';
import FormInput from './CustomTextField';
import useStyles from './Checkout/styles';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "./style.css"


const FinalForm = () => {

  const [expanded, setExpanded] = useState(false);
  const [currentAmount, setCurrentAmount] = useState(0);
  const [finalAmount, setFinalAmount] = useState(0);

  const { id, quantity, amount, ticket_type, handling_fee } = useParams();
  useEffect(() => {
    // Assuming handling_fee is a variable containing the handling fee value
    const handling_fee_numeric = parseInt(amount) * parseInt(handling_fee) / 100;
    const IGST = handling_fee_numeric * 9 / 100;
    const CGST = handling_fee_numeric * 9 / 100;
    setCurrentAmount(CGST + IGST + handling_fee_numeric)
    setFinalAmount(String(parseInt(amount) + IGST + CGST + handling_fee_numeric))
  }, [])

  const handlePanelChange = () => {
    setExpanded(!expanded);
  };
  const classes = useStyles();
  const steps = ['User details', 'Payment details'];
  const [activeStep, setActiveStep] = useState(0);
  const [bookingID, setBooknigID] = useState('');
  const methods = useForm();
  const couponRef = useRef();
  const { handleSubmit, register } = methods;

  const getAuthToken = () => {
    return localStorage.getItem('dummy_token2');
  };

  const handleCoupon = async (e) => {
    e.preventDefault();


    try {

      const data = {
        coupon_code: couponRef.current.value,
        event_id: id,
      }
      const queryParams = new URLSearchParams(data);

      const response = await axios.get(`https://fourband-app-2n2vh.ondigitalocean.app/v1/data/verify-coupon/${queryParams}/`,
        {
          headers: {
            'Token': `${getAuthToken()}`,
            'Authorization': `Token ${getAuthToken()}`,
            'Content-Type': 'application/json',
          },
        }
      );

      // Check the response status code for success (e.g., HTTP 200)
      if (response.status === 200 || response.status === 201) {

        console.log('response:', response.data);

      } else {
        // Handle unexpected response status codes
        console.error('Unexpected response:', response.status);
        // alert('An unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle network or request errors
      console.error('Error:', error);

      // Check if the error is due to unauthorized access (401)
      if (error.response && error.response.status === 401) {
        alert('Unauthorized access. Please log in again.');
        // You might want to redirect the user to the login page here
        // window.location='/';
      } else {
        // Handle other errors
        alert('An error occurred. Please try again later.');
      }
    }


  }

  return (
    <>
      <CssBaseline />
      <div className={classes.toolbar} />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          {/* <Typography variant="h4" align="center">Confirm Your Order</Typography> */}
          <br />
          <Typography variant="h6" gutterBottom>Do you Have Any Coupon?</Typography>
          <br />
          <FormProvider {...methods}>
            <form >
              <Grid container spacing={3}>
                <div className='coupon_container'>
                  {/* <label className='p-1'>Apply Coupon</label> */}
                  <div className='coupon'>
                    <input className='custom_input' type="text" ref={couponRef} placeholder='Enter Coupon Code' name="" id="" />
                    <button onClick={handleCoupon}>Apply</button>
                  </div>
                  <label hidden={true} className='coupon_status'>Valid Coupon</label>
                </div>
              </Grid>
              <br />
              <br />
              <br />
              <span className='end'>Sub-total <h5><b>₹{amount}</b></h5></span>

              <Accordion expanded={expanded} onChange={handlePanelChange}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <span className='end'>More-details</span>
                  <label hidden={true} className='coupon_status'>Valid Coupon</label>
                </AccordionSummary>
                <AccordionDetails>
                  <span className='end'>Base Price <h5><b>₹{amount}</b></h5></span>
                  <span className='end'>Booking fee + CGST + SGST &nbsp;<h5><b>₹{(+currentAmount).toFixed(2)}</b></h5></span>
                </AccordionDetails>
              </Accordion>
              {/* <div className='custom_hr' style={{marginBottom:"5px"}}>
                                </div> */}
              <br />
              <span className='end'>Final Price <h5><b>₹{(+finalAmount).toFixed(2)}</b></h5></span>
              <br />

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {/* <Button component={Link} variant="outlined" to="/" style={{ backgroundColor: 'black', color: 'white' }}>Home</Button> */}
                <Button
                  component={Link}
                  to={`/address-form/${id}/${quantity}/${(+finalAmount).toFixed(2)}/${ticket_type}/${handling_fee}`}
                  variant="contained" color="primary" style={{ backgroundColor: 'red', color: 'white' }}>Continue</Button>
              </div>
            </form>
          </FormProvider>
        </Paper>
      </main>


    </>
  );
};

export default FinalForm;
